import React, { FunctionComponent } from 'react'
import Layout from '../../components/LayoutBlog'
import { graphql } from 'gatsby'
import { Post } from '../../utils/models'
import { Subheader } from '../../components/Subheader'
import { SEO } from '../../components/SEO'
import { PostGrid } from '../../components/PostGrid'

interface ArchivePageProps {
  data: {
    allPosts: {
      edges: Array<{ node: Post }>
    }
  }
  location: Location
}

const ArchivePage: FunctionComponent<ArchivePageProps> = ({
  data,
  location,
}) => {
  const posts = data.allPosts.edges.map((node) => node.node) as Post[]

  return (
    <Layout bigHeader={false}>
      <SEO location={location} title={`Arquivo de Postagens`} type={`Series`} />
      <Subheader
        backgroundColor={`#D8C800`}
        title={`Arquivo de Postagens`}
        subtitle={`${posts.length} posts`}
      />
      <PostGrid posts={posts} />
    </Layout>
  )
}

export default ArchivePage

export const query = graphql`
  {
    allPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/posts/)/" } }
      sort: { fields: frontmatter___created, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            tags
            excerpt
            created
            createdPretty: created(formatString: "DD MMMM, YYYY")
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  quality: 100
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`
